<div class="container">
    <div cdkDropList class="servicesSelected" (cdkDropListDropped)="drop($event)">
        <div class="row title-brand">
            <label for="brand">
                Elige una marca:
            </label>
            <select [(ngModel)]="id_brand" (change)="changeBrand($event.target.value)">
                <option *ngFor="let brand of brands" [value]="brand.id">{{brand.name}}</option>
            </select>
        </div>
        <div class="warning-services" *ngIf="selected.length == 0">
            <p>¡No ha seleccionado ningún servicio!</p>
        </div>
        <div *ngFor="let service of selected; let i = index" class="pop" cdkDrag>
            <!--
            <div class="">
                <input type="number" [(ngModel)]="service.order" [disabled]="!service.status" />
            </div>-->
            <div class="">
                <label>({{i+1}}) {{service.title}}</label>
            </div>
        </div>
    </div>
    <div class="services">
        <div *ngFor="let service of services">
            <label>{{service.title}}</label>
            <input type="checkbox" [(ngModel)]="service.status" (change)="changeService($event, service)" />
        </div>
    </div>
</div>