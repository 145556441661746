import { Injectable, ɵɵsanitizeUrlOrResourceUrl } from '@angular/core';
import { Http, Response, Headers} from '@angular/http';
import { SharingService } from './sharing.service';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import { Promotion } from '../models/promotion';
import { URL } from '../config';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ApserviceService {

  private publicURL = URL.host_backend + URL.endpoint_public;  // URL to web api
  private adminUrl = URL.host_backend + URL.endpoint_admin;
  public driverURL =  URL.host_backend + URL.endpoint_driver;
  public imgURL =  URL.image_url; //URL static upload folder


  constructor(
    private http: Http,
    private _ss: SharingService
  ) { }


  private getHeadersPublic() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('api-key', '@?Uq?DKYLd3bY_e*N%L46YKQ%M!ZcwABKAS_%mRBy%KD8D7#dagx5*A*hv2UR3RwufLvraDnxYmS7m+dE!_nybvNg^bZgHQbZ=Yp#g#Y6yDgLY7H!dmH=ZEuZf#A9yRD^kLw8H8s9MGQxCCWLWHN6-CCFYY$@uC24EXp*gm3==bLWvM6MUm%p9kkSyhNqegv3@qW@DN%?ux4zcLCv$W8V=CbBJ4cS@XA%CBwEA=gW?6CKMUE^%ACB_&*EchqV#Sq');
    headers.append('Authorization', this._ss.getWorkShopSettings() ? this._ss.getWorkShopSettings().token : '');
    return headers;
  }

  /*Configuracion Taller */
  getMultisiteSettings() {
    return this.http.get(this.publicURL + 'settings/' + this._ss.getWorkShopSettings().id,
    { headers: this.getHeadersPublic() }).
      catch(this.handleError).
      map((response: Response) => response.json()).
      catch(this.handleError);
  }

  //Crear la configuración básica del taller
  addMultisiteSettings(settings){
    return this.http.post(this.adminUrl + 'settings/',
      {
        id_workshop : this._ss.getWorkShopSettings().id,

        site_title: settings.site_title,
        site_description: settings.site_description,

        phone: settings.phone,
        email: settings.email,
        timetable: settings.timetable,
        address: settings.address,

        logo: settings.logo
      },
      { headers: this.getHeadersPublic() }).catch(this.handleError)
      .map((response: Response) => response.json()).catch(this.handleError);
  }

  //Modicar configuración básica del taller
  setMultisiteSettings(settings){
    return this.http.put(this.adminUrl + 'settings/',
      {
        id: settings.id,
        workshop_id : this._ss.getWorkShopSettings().id,

        site_title: settings.site_title,
        site_description: settings.site_description,

        phone: settings.phone,
        email: settings.email,
        timetable: settings.timetable,
        address: settings.address,

        logo: settings.logo
      },
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cargar todas las promociones
  getMultisitePromotions(){
    return this.http.get(this.publicURL + 'promotions/' + this._ss.getWorkShopSettings().id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cambiar el estado de una promoción
  changePromotionStatus(id){
    return this.http.put(this.adminUrl + 'promotion/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cambiar el estado de una promoción
  changePromotionFeatured(id){
    return this.http.put(this.adminUrl + 'promotion/featured/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Eliminar una promoción
  deletePromotion(id: Number){
    return this.http.delete(this.adminUrl + 'promotion/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Añadir una promoción
  addPromotion(promotion: Promotion){
    return this.http.post(this.adminUrl + 'promotion/',  {id_workshop:this._ss.getWorkShopSettings().id, id:promotion.id, title:promotion.title,
      description: promotion.description,image: promotion.image,banner_image: promotion.banner_image, start_date: promotion.start_date,
      expiration_date: promotion.expiration_date, status:promotion.status },
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cargar todas las marcas
  getBrands(){
    return this.http.get(this.publicURL + 'brands/',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cargar los servicios de la marca
  getServices(){
    return this.http.get(this.publicURL + 'services/' + this._ss.getWorkShopSettings().id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cargar los servicios de la marca
  getBrandServices(id){
    return this.http.get(this.publicURL + 'brand/'+id+'/services/',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  //Modicar servicios del taller
  setWorkShopServices(services){
    return this.http.put(this.adminUrl + 'services/', {services: services, id_workshop: this._ss.getWorkShopSettings().id},
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cargar todas las aseguradoras
  getPartners(){
    return this.http.get(this.publicURL + 'partners/',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cargar las aseguradoras de un taller
  getWorkshopPartners(){
    return this.http.get(this.publicURL + 'partners/'+ this._ss.getWorkShopSettings().id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Modificar Aseguradoras
  setWorkShopPartners(partners){
    return this.http.put(this.adminUrl + 'partners/', {partners: partners, id_workshop: this._ss.getWorkShopSettings().id},
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getWorkShopCenters(){
    return this.http.get(this.driverURL + 'workshop/'+this._ss.getWorkShopSettings().id+'/centers',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  deleteWorkshopCenter(id){
    return this.http.delete(this.adminUrl + 'center/'+id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getPagesByWorkshopId(){
    return this.http.get(this.publicURL + 'pages/' + this._ss.getWorkShopSettings().id,
      { headers: this.getHeadersPublic() }).catch(this.handleError)
      .map((response: Response) => response.json()).catch(this.handleError);
  }

  addPagestoWorkshop(){
    return this.http.post(this.adminUrl + 'pages/',{id_workshop: this._ss.getWorkShopSettings().id},
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getPageDetailByPageTypeForWorkshop(idPageType){
    return this.http.get(this.publicURL + 'pagedetail/' + this._ss.getWorkShopSettings().id + '/' + idPageType,
      { headers: this.getHeadersPublic() }).catch(this.handleError)
      .map((response: Response) => response.json()).catch(this.handleError);
  }

  setWorkshopPolicies(policies){
    return this.http.put(this.adminUrl + 'policies/',{policies: policies},
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para cargar los centros del Workhshop
  getWorkshopCenters(){
    return this.http.get(this.driverURL + 'workshop/'+this._ss.getWorkShopSettings().id+'/centers',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para guardar un centro
  setWorkShopCenter(center){
     // Método para cargar los centros del Workhshop
    return this.http.put(this.driverURL + 'workshop/center',{center: center},
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  setPageDetails(page){
    return this.http.put(this.adminUrl + 'pagedetail/', {id: page.id, id_page_type: page.id_page_type, id_workshop: this._ss.getWorkShopSettings().id,
       name: page.name, banner_title: page.banner_title, banner_image: page.banner_image, content_title: page.content_title,
        content_subtitle: page.content_subtitle, content_description: page.content_description, content_image:page.content_image },
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getSocialNetworkTypes(){
    return this.http.get(this.publicURL + 'socialnetworks',
   { headers: this.getHeadersPublic() }).catch(this.handleError)
   .map((response: Response) => response.json()).catch(this.handleError);
  }

  getSocialNetworks(){
    return this.http.get(this.publicURL + 'socialnetworks/' + this._ss.getWorkShopSettings().id,
   { headers: this.getHeadersPublic() }).catch(this.handleError)
   .map((response: Response) => response.json()).catch(this.handleError);
  }

  setSocialNetworks(socialnetworks){
    return this.http.put(this.adminUrl + 'socialnetworks/', {socialnetworks: socialnetworks, id_workshop: this._ss.getWorkShopSettings().id},
   { headers: this.getHeadersPublic() }).catch(this.handleError)
   .map((response: Response) => response.json()).catch(this.handleError);
  }

  public handleError(error: any) {    // log error
    console.log('Error en com service');
    console.log(error);
    // throw an application level error
    return throwError(error);
  }

}
