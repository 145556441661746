<div class="form">
    <label for="title">Título del sitio</label>
    <input type="text" name="title" [(ngModel)]="settings.site_title" placeholder="Título del sitio" />
    <label for="title">Descripción del sitio</label>
    <input type="text" name="description" [(ngModel)]="settings.site_description" placeholder="Descripción" />
    <div class="row">
        <div class="">
            <label for="title">Teléfono</label>
            <input type="text" name="phone" [(ngModel)]="settings.phone" placeholder="(+34) 000 00 00 00" />
        </div>
        <div>
            <label for="title">Correo</label>
            <input type="text" name="email" [(ngModel)]="settings.email" placeholder="tucorreo@tucorreo.es" />
        </div>

    </div>
    <label for="title">Horario</label>
    <input type="text" name="timetable" [(ngModel)]="settings.timetable" placeholder="Horario" />
    <label for="title">Dirección</label>
    <input type="text" name="address" [(ngModel)]="settings.address" placeholder="Dirección" />

    <label for="logo">Logotipo </label>
    <div class="logo">
        <div class="logo-content">
            <img [src]="_ap.imgURL + settings.logo" />
            <img [src]="imgURL" height="200" *ngIf="imgURL">
            <span>(Logo Actual)</span>
        </div>

    </div>
    <div class="uploading">
        <label for="upload-file" class="custom-file-upload">
            <i class="fa fa-cloud-upload"></i>
          Seleccione una imagen</label>
        <span class="file-name">{{ filename }}</span>
        <input id="upload-file" #selectedPicture type="file" name="photo" ng2FileSelect [uploader]="uploader" />
        <div class="progress" *ngIf="isuploadingProgress">
            <p style="padding: 0; margin: 0">Archivo subido</p>
            <div class="progress-bar" [style.width]="uploadStatusProgress">
                <p class="progress-content" role="progressbar"></p>
            </div>
            <span class="progress-num">{{ uploadStatusProgress }}</span>
            <!-- <button class="cancelUploadBtn" (click)="cancelUpload()">Cancel</button> -->
        </div>
        <div class="drop-zone" [ngClass]="{'file-over': hasBaseDropZoneOver}" ng2FileDrop [uploader]="uploader" (click)="fileInput.click()">

            Por favor, elige una imagen
            <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" />
        </div>
    </div>
</div>



