import { Component, OnInit, ElementRef, AfterViewInit, Input, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { FileUploader } from 'ng2-file-upload';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit, OnDestroy {

  @ViewChild('selectedPicture') selectedPicture: any;

  public settings = {
    id: "",
    id_workshop: "",

    site_title: "",
    site_description: "",

    email: "",
    phone: "",
    timetable: "",
    address: "",

    logo: "",

    banner_home_description: "",
    banner_home_image: "",
    banner_home_title: "",

    banner_one_description: "",
    banner_one_image: "",
    banner_one_title: "",

    banner_two_description: "",
    banner_two_image: "",
    banner_two_title: "",
  };

  saveSubscription;
  newSubscription;

  imagePath;
  message;
  imgURL;

  isuploadingProgress;
  uploadStatusProgress;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;
  filename;
  uploader:FileUploader;
  saveWithImage: boolean = false;

  constructor(
    public _ss:SharingService,
    public _ap:ApserviceService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private _ns: NotifierService,
  ) {

    if(this._ss.workshopData.id){
      this.router.navigate(['configuracion'], this._ss.workshopData.id);
    }else{
      let idWorkshop = this.route.snapshot.paramMap.get("id");
      this._ss.workshopData.id = idWorkshop;
    }

    this.uploader = new FileUploader({
      url: this._ss.adminURL + 'upload',
      method: "POST",
      queueLimit: 1,
      allowedMimeType: ['image/png', 'image/jpeg', 'image/svg+xml'],
      itemAlias: 'photo',
    });
  }


  ngOnInit() {
    this._ss.saveButton = true;
    this._ss.newButton = false;
    this._ss.titleSection = 'Configuración';
    this._ap.getMultisiteSettings().subscribe(response => {
      console.log(response);
      if(response.result == 'OK'){
        console.log(response.msg);
        this.settings = response.data[0];
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    },response => {
      if(response.status == 404){
        console.log("No hay configuración para el taller indicado... preparando para crearlo");
        this.settings.id = "";
      }else {
        console.log("Se ha producido un error");
        console.log(JSON.parse(response._body).msg);
      }

    });

    this.saveSubscription = this._ss.$save.subscribe(() => {
      this.save();
    });

      //override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.

      this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false;
        this.filename = file.file.name;
        if (this.uploader.queue.length >= 1) {
          this.saveWithImage = true;
        }
      };

      this.uploader.onBeforeUploadItem = (file)=>{
        console.log(file);
      };

       //overide the onCompleteItem property of the uploader so we are
       //able to deal with the server response.

       this.uploader.onProgressItem = (fileItem: any, progress: any)=>{
        this.isuploadingProgress = true;
         this.uploadStatusProgress = progress+'%';
       }

       this.uploader.onCompleteItem = (item:any, data:any, status:any, headers:any) => {
          data = JSON.parse(data);
         if(data.succes === 'OK'){
          console.log(data);
            let filename = data.file.filename;
            this.settings.logo = filename;
            this.addOrUpdateSettings();
            }else{
              console.log("Se ha producido un error");
            }
          }

            this.uploader.onErrorItem = (item: any, response: string, status: number, headers:any) =>{
              console.log(response);
            }
  }

  save(){
    console.log(this.saveWithImage);
    if(this.saveWithImage){
      this.uploader.uploadAll();
    }else{
      this.addOrUpdateSettings();
    }
  }

  private addOrUpdateSettings() {
    if (this.settings.id == "")
      this.addSettings();
    else
      this.updateSettings();
  }

  clearSelectedPicture() {
    this.filename = '';
    this.isuploadingProgress = '';
    this.uploadStatusProgress = '';
    this.selectedPicture.nativeElement.value = '';
    this.uploader.clearQueue();
    this.saveWithImage = false;
  }

  addSettings(){
    this._ap.addMultisiteSettings(this.settings).subscribe(response => {
      if(response.result == 'OK'){
        console.log(response.msg);
        this.clearSelectedPicture();
        this._ns.notify("success", "Guardado correctamente!");
      }else{
        this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
    this._ap.getMultisiteSettings().subscribe(response => {
      console.log(response);
      if(response.result == 'OK'){
        console.log(response.msg);
        this.settings = response.data[0];
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    },response => {
      if(response.status == 404){
        console.log("No hay configuración para el taller indicado... preparando para crearlo");
        this.settings.id = "";
      }else {
        console.log("Se ha producido un error");
        console.log(JSON.parse(response._body).msg);
      }

    });
  }

  updateSettings(){
    this._ap.setMultisiteSettings(this.settings).subscribe(response => {
      if(response.result == 'OK'){
        console.log(response.msg);
        this.clearSelectedPicture();
        this._ns.notify("success", "Guardado correctamente!");
      }else{
        this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.saveSubscription.unsubscribe();
  }

}
