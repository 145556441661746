<div class="container-menu">
    <ul class="nav" [ngClass]="!_ss.isOpen ? 'closed' : ''">
        <!--
        <li (click)="openMenu()">
            <a [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faBars"></fa-icon>
                </div>
                <span>Cerrar</span>
            </a>
        </li>
        -->
     
        <li>
            <a [routerLink]="['/configuracion']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faCog"></fa-icon>
                </div>
                <span>Configuración</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/paginas']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faFile"></fa-icon>
                </div>
                <span>Páginas</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/promociones']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faDolar"></fa-icon>
                </div>
                <span>Promociones</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/servicios']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faWrench"></fa-icon>
                </div>
                <span>Servicios</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/aseguradoras']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faCar"></fa-icon>
                </div>
                <span>Aseguradoras</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/centros']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faChat"></fa-icon>
                </div>
                <span>Centros</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/social']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <span class="social-icon" [inlineSVG]="facebook"></span>
                </div>
                <span>Redes Sociales</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/politicasycondiciones']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faBook"></fa-icon>
                </div>
                <span>Políticas y Condiciones</span>
            </a>
        </li>
    </ul>
</div>
