export const URL = {
    host_backend: 'https://test.driver360.es/back/multisite/api/v1',
    image_url: 'https://test.driver360.es/multisite-static/',

    qcar_image_url: 'https://qcar.es/portalvo/public/imagesVehicles/',
    endpoint_public: '/public/',
    endpoint_admin: '/admin/',
    endpoint_driver: '/driver/',
    endpoint_qcar: '/qcar/'
}
