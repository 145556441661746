import { Injectable, EventEmitter } from '@angular/core';
import { URL } from '../config';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  isOpen = false;
  saveButton = false;
  newButton = false;
  storageName = "workshopCookie";
  titleSection;
  adminURL = URL.host_backend + URL.endpoint_admin;
  imgURL = URL.image_url;
  workshopData = {id:null, name:null, token:'Basic YW5ndWxhci13YXJlaG91c2Utc2VydmljZXM6MTIzNDU2'};
  $save = new EventEmitter();
  $create = new EventEmitter();

  constructor() { }

  getWorkShopSettings() {
    //this.workshopData = localStorage.getItem(this.storageName);
    return this.workshopData;
  }

  saveEvent(){
    console.log("Guardando....");
    this.$save.emit();
  }

  createEvent(){
    console.log("Creando....");
    this.$create.emit();
  }
}
