<div id="page-tabs-component">

    <ul class="tabs">
        <li class="page" *ngFor="let page of pages">
            <div class="tab" [ngClass]="page.class" [class.active]="page.active" *ngIf="page.status" (click)="changeActivePageTab(page.id_page_type)">
                <span>{{page.name}}</span>
            </div>
        </li>
    </ul>

    <div class="tab-container" *ngIf="activePageTab">
        <app-page-details [idPageType]="activePageTab">
        </app-page-details>
    </div>

</div>
