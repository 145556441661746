import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { RouterModule, Routes } from '@angular/router';
import { SharingService } from './services/sharing.service';
import { ApserviceService } from './services/apservice.service';
import { PagesComponent } from './components/pages/pages.component';
import { ServicesComponent } from './components/services/services.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { WorkshopCentersComponent } from './components/centers/workshop-centers.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InsurancesComponent } from './components/insurances/insurances.component';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './components/centers/map/map.component';
import { PageDetailsComponent } from './components/pages/page-details/page-details.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ModifyCenterComponent } from './components/centers/modify-center/modify-center.component';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { InlineSVGModule } from 'ng-inline-svg';
import { SocialnetworksComponent } from './components/socialnetworks/socialnetworks.component';
import { PoliciesandconditionsComponent } from './components/policiesandconditions/policiesandconditions.component';

const routes: Routes = [
  { path: 'configuracion', component: ConfigurationComponent},
  { path: 'configuracion/:id', component: ConfigurationComponent},
  { path: 'paginas', component: PagesComponent},
  { path: 'servicios', component: ServicesComponent},
  { path: 'aseguradoras', component: InsurancesComponent},
  { path: 'promociones', component: PromotionsComponent},
  { path: 'centros', component: WorkshopCentersComponent},
  { path: 'social', component: SocialnetworksComponent},
  { path: 'politicasycondiciones', component: PoliciesandconditionsComponent},
  //{ path: 'configuracion', component: ContactComponent, canActivate: [AuthGuardService]},
  { path: '**', pathMatch: 'full', redirectTo: '/configuracion' }
];

const options: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 12
      },
      vertical: {
          position: 'bottom',
          distance: 50,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 3500,
      onClick: false,
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ConfigurationComponent,
    PagesComponent,
    ServicesComponent,
    PromotionsComponent,
    WorkshopCentersComponent,
    HeaderComponent,
    InsurancesComponent,
    MapComponent,
    PageDetailsComponent,
    ModifyCenterComponent,
    SocialnetworksComponent,
    PoliciesandconditionsComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      clientId: '<mandatory>',
      //apiVersion: 'xxx', // optional
      //channel: 'yyy', // optional
      apiKey: 'AIzaSyB5ia-uuwbrk59z6iubZULpe4x_bTJVCRQ',
      language: 'es',
      libraries: ['geometry', 'places'],
    }),
    HttpModule,
    BrowserModule,
    HttpClientModule,
    DragDropModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    FontAwesomeModule,
    BrowserModule,
    InlineSVGModule.forRoot(),
    NotifierModule.withConfig(options),
    AppRoutingModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore' }),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  providers: [SharingService, ApserviceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
