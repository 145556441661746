import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { PAGE_TYPES_IDS } from 'src/app/constants';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-policiesandconditions',
  templateUrl: './policiesandconditions.component.html',
  styleUrls: ['./policiesandconditions.component.scss']
})
export class PoliciesandconditionsComponent implements OnInit, OnDestroy {

  pages = [];
  pageDetails = [];
  saveSubscription;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '400',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Introduce una descripción...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns: NotifierService
  ) {
    this._ss.titleSection = 'Políticas y condiciones'
   }

  ngOnInit(): void {
    this.saveSubscription = this._ss.$save.subscribe(() => {
      this.save();
    });
    this.getPoliciesPages();
  }

  getPoliciesPages(){
    this._ap.getPagesByWorkshopId().subscribe((response) =>{
      if(response.result == 'OK'){
        console.log("getPagesByWorkshopId");
        console.log(response.msg);
        this.pages = response.data;
        this.pages = this.pages.filter(x => x.status == 1);
        this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.SERVICE_PAGE_TYPE_ID);
        this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.PROMOTION_PAGE_TYPE_ID);
        this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.WHO_WE_ARE_PAGE_TYPE_ID);
        this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.CONTACT_PAGE_TYPE_ID);
        this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.VEHICLE_PAGE_TYPE_ID);
        this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.INSURANCE_PAGE_TYPE_ID);
        this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.RESERVATION_PAGE_TYPE_ID);
        console.log(this.pages);
        this.getPageDetails();
      }else{
        console.log("Se ha producido un error!");
        console.log(response.msg);
      }
    });
  }

  getPageDetails(){
    this.pages.forEach(page => {
        this._ap.getPageDetailByPageTypeForWorkshop(page.id_page_type).subscribe(response =>{
          if(response.result == 'OK'){
            console.log(response.msg);
            let page = response.data[0];
            page.name = this.pages.find(x => x.id_page_type == page.id_page_type).name;
            this.pageDetails.push(page);
          }else{
            console.log("Se ha producido un error");
            console.log(response.msg);
          }
        });
    });
    console.log(this.pageDetails);
  }
  
  save(){
    console.log(this.pageDetails);
    this._ap.setWorkshopPolicies(this.pageDetails).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this._ns.notify('success','Guardado correctamente!');
      }else{
        this._ns.notify('success','Error: al modificar los datos!');
      }
    },(err)=>{
      console.log(err);
      this._ns.notify('success','Error: al conectarse con el servicio!');
    });
  }

  ngOnDestroy(){
    this.saveSubscription.unsubscribe();
  }

}
