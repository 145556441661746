import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { faPhone, faEnvelope, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Center } from "../../models/center";

@Component({
  selector: 'app-workshop-centers',
  templateUrl: './workshop-centers.component.html',
  styleUrls: ['./workshop-centers.component.scss']
})
export class WorkshopCentersComponent implements OnInit {
  
  center: Center = {
    "id": null,
    "phone": null,
    "name": null,
    "email": null,
    "description": '',
    "address": {
      "apartment": null,
      "block": null,
      "council": null,
      "country":null,
      "countryTranslation": null,
      "latitude": null,
      "longitude": null,
      "number": null,
      "postalCode": null,
      "community": null,
      "province": null,
      "stair": null,
      "street": null,
    }  
  };

  autocompleteAddress = '';

  centers = [];
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faArrow = faChevronDown;
  faClose = faTimes;

  constructor(
    public _ss: SharingService,
    public _ap: ApserviceService
  ) { }

  ngOnInit(): void {
    this._ss.titleSection = 'Centros';
    this.getCenters();
    this._ss.newButton = false;
    this._ss.saveButton = false;
  }

  getCenters(){
    this._ap.getWorkShopCenters().subscribe(response => {
      if(response.result == 'OK'){
        console.log(response.msg);
        this.centers = response.data;
        console.log(this.centers);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  setCenter(id){
    console.log(id);
    console.log(this.center);
    this.center = this.centers.find(x => x.id == id);
    console.log(this.center);
    this.autocompleteAddress = this.center.address.street+', '+this.center.address.number+ ', '+this.center.address.council+', '+this.center.address.province+', '+this.center.address.country;
  }

  getAddressData(data){
    console.log(data);
  }
}
