import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.scss']
})
export class InsurancesComponent implements OnInit, OnDestroy {

  insurances;
  saveSubscription;
  selected = [];

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns:NotifierService
  ) { 
    this._ss.titleSection = 'Colaboradores';
  }

  ngOnInit(): void {
    this._ss.saveButton = true;
    this._ss.newButton = false;
    this.saveSubscription = this._ss.$save.subscribe(() => {
      this.savePartners();
    });

    this._ap.getPartners().subscribe(response=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.insurances = response.data;
        this.getPartners();
        console.log(this.insurances);
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selected, event.previousIndex, event.currentIndex);
    this.selected[event.previousIndex].order = event.currentIndex;
    console.log(event.previousIndex);
    console.log(event.currentIndex);
  }


  getPartners(){
    this._ap.getWorkshopPartners().subscribe(response=>{
      if(response.result == 'OK'){
        this.selected = response.data;
        this.insurances.forEach(element => {
          let pos = this.selected.findIndex(x => x.id_partner == element.id);
          if(pos != -1){
            element.status = true;
          }else{
            element.status = false;
          }
        });
        console.log(response.msg);
      }else{
        console.log(response.msg);
      }
    });
  }

  changeInsurance(event, element){
    console.log(event)
    if(event.target.checked){
      element.id_partner = element.id;
      this.selected.push(element);
    }else{
      console.log(this.selected);
      console.log(element);
     this.selected = this.selected.filter(x => x.id_partner != element.id);
    }
  }

  savePartners(){
    console.log(this.selected);
    console.log("caca");
    this._ap.setWorkShopPartners(this.selected).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this._ns.notify("success", "Guardado correctamente!");
      }else{
        this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
        console.log(response.msg);
      }
    });
  }

  ngOnDestroy() {
    this.saveSubscription.unsubscribe();
  }

}
