<div class="container">
    <div cdkDropList class="servicesSelected" (cdkDropListDropped)="drop($event)">
        <div class="warning-services" *ngIf="selected.length == 0">
            <p>¡No ha seleccionado ninguna aseguradora!</p>
        </div>
        <div *ngFor="let insurance of selected; let i = index" class="pop" cdkDrag>
            <!--
            <div class="">
                <input type="number" [(ngModel)]="service.order" [disabled]="!service.status" />
            </div>-->
            <div class="">
                <label>({{i+1}}) {{insurance.title}}</label>
            </div>
        </div>
    </div>
    <div class="services">
        <div *ngFor="let insurance of insurances">
            <label>{{insurance.title}}</label>
            <input type="checkbox" [(ngModel)]="insurance.status" (change)="changeInsurance($event, insurance)" />
        </div>
    </div>
</div>